*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f9f9f9;
}

*::-webkit-scrollbar-thumb {
  background-color: #a0a2ac;
  border-radius: 5px;
}
.react-daterange-picker__wrapper {
  border-radius: 5px;
  background-color: #fff;
  border-color: #c4c4c4;
  border-width: 0.5px;
  height: 40px;
  padding: 4px;
  /* '&:hover': { borderColor: theme.palette.secondary.main }, */
}
